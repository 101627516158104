import {
  Component,
  ComponentRef,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { FilterEditor, FilterRecord } from '@services/core-services/filters';
import { CdkPortalOutletAttachedRef, ComponentPortal, PortalModule } from '@angular/cdk/portal';
import { FilterService } from '@services/core-services/filters/filter.service';
import { ComponentType } from '@angular/cdk/overlay';

@Component({
  selector: 'app-filter-entry-editor-container',
  standalone: true,
  imports: [CommonModule, PortalModule],
  templateUrl: './filter-entry-editor-container.component.html',
  styleUrl: './filter-entry-editor-container.component.scss',
})
export class FilterEntryEditorContainerComponent implements OnChanges {
  @Input() record: FilterRecord | undefined;
  @Input() value: any | undefined;
  @Output() onChange: EventEmitter<any> = new EventEmitter<any>();

  public editorRef: ComponentRef<FilterEditor> | undefined;
  public editorPortal: ComponentPortal<FilterEditor> | undefined;

  constructor(private readonly filterService: FilterService) {}

  ngOnChanges(changes: SimpleChanges): void {
    const record = (changes['record']?.currentValue || this.record) as FilterRecord;

    const Component = this.filterService.resolveEditorComponent(
      record
    ) as ComponentType<FilterEditor>;

    this.editorPortal = Component ? new ComponentPortal(Component) : undefined;
  }

  onFormComponentRender(ref: CdkPortalOutletAttachedRef) {
    this.editorRef = ref as ComponentRef<FilterEditor>;
    this.editorRef.instance.record = this.record;
    this.editorRef.instance.onChange?.subscribe((value: any) => {
      this.onChange.emit(value);
    });
  }
}
