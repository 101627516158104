import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SearchComponent } from '@enginuity/core/molecules/search/search.component';
import { SecondaryBtnsComponent } from '@enginuity/core/molecules/secondary-btns/secondary-btns.component';
import { CdkConnectedOverlay, CdkOverlayOrigin } from '@angular/cdk/overlay';
import {
  FilterContext,
  FilterGroupType,
  FilterSetup,
  FilterSetupEntry,
} from '@services/core-services/filters';
import { CeIconComponent } from '@enginuity/core/atoms/ce-icon/ce-icon.component';
import { IconContainerComponent } from '@enginuity/core/atoms/icon-container/icon-container.component';
import { FilterPreviewComponent } from '@enginuity/core/organisms/filtering/filter-preview/filter-preview.component';
import { FilterRecord } from '@services/core-services/filters/fitler-record';
import { FilterService } from '@services/core-services/filters/filter.service';
import { Query } from '@services/core-services/models';
import { FilterGroup } from '@services/core-services/filters/filter-group';
import { ActionBtnComponent } from '@enginuity/core/molecules/action-btn/action-btn.component';
import { CounterComponent } from '@enginuity/core/molecules/counter/counter.component';
import { DropdownSinglePieceComponent } from '@enginuity/core/molecules/dropdown-single-piece/dropdown-single-piece.component';
import { LinkBtnComponent } from '@enginuity/core/molecules/tertiary/link-btn.component';
import { FilterNavigationComponent } from '@enginuity/core/organisms/filtering/filter-navigation/filter-navigation.component';

@Component({
  selector: 'app-filter',
  standalone: true,
  templateUrl: './filter.component.html',
  styleUrl: './filter.component.scss',
  imports: [
    CommonModule,
    SearchComponent,
    SecondaryBtnsComponent,
    CeIconComponent,
    IconContainerComponent,
    FilterPreviewComponent,
    CdkConnectedOverlay,
    CdkOverlayOrigin,
    ActionBtnComponent,
    CounterComponent,
    DropdownSinglePieceComponent,
    LinkBtnComponent,
    FilterNavigationComponent,
  ],
})
export class FilterComponent implements OnChanges {
  @Input() allowDuplicates: boolean = false;
  @Input() query: Query | undefined | null;
  @Input({ required: true }) setup: FilterSetup | undefined | null;
  @Input() enableCustomization: boolean = false;

  @Output() onSearch: EventEmitter<string> = new EventEmitter<string>();
  @Output() onChange: EventEmitter<FilterRecord[]> = new EventEmitter<FilterRecord[]>();
  @Output() onFilterSave: EventEmitter<FilterContext> = new EventEmitter<FilterContext>();
  @Output() onClear: EventEmitter<any> = new EventEmitter<any>();
  @Output() onFilterRemove: EventEmitter<FilterContext> = new EventEmitter<FilterContext>();

  public selectionOpen: boolean = false;
  public groups: FilterGroup[] = [];
  public records: FilterRecord[] = [];
  public search: string = '';
  public filtersSearch: string = '';
  @Input() filters!: FilterContext[] | undefined | null;

  constructor(private readonly filter: FilterService) {}

  ngOnChanges(changes: SimpleChanges): void {
    const setup = changes['setup']?.currentValue || this.setup;
    const query = (changes['query']?.currentValue || this.query) as Query;

    if (setup || query) {
      this.initialize(setup, query);
    }
  }

  onRecordRemove(record: FilterRecord) {
    this.records = this.filter.removeRecord(this.records, record);
    this.refresh(this.records);
    this.onChange.emit(this.records);
  }

  onRecordsChange(records: FilterRecord[]) {
    this.records = records;
    this.onChange.emit(this.records);
  }

  onTextSearch(value: string) {
    this.onSearch.emit(value);
  }

  onFilterSearch(q: string) {
    this.filtersSearch = q;
    this.refresh(this.records);
  }

  handleFilterSave(context: FilterContext) {
    this.onFilterSave.emit(context);
  }

  clearFilter() {
    this.toggleSelection(false);
    this.records = [];
    this.onClear.emit();
    this.onChange.emit(this.records);
    this.refresh(this.records);
  }

  addFilter(entry: FilterSetupEntry) {
    const { records } = this;
    records.push({ active: true, entry, rule: { field: entry.field, type: FilterGroupType.Data } });
    this.refresh(records);
  }

  toggleSelection(flag?: boolean | undefined) {
    flag !== undefined ? (this.selectionOpen = flag) : (this.selectionOpen = !this.selectionOpen);
  }

  onFilterNavigationChange(context: FilterContext) {
    this.onRecordsChange(context.records || []);
  }

  handleFilterRemove({ context, active }: { context: FilterContext; active: boolean }) {
    if (active) {
      this.clearFilter();
      this.onFilterRemove.emit(context);
    }
  }

  private refresh(records: FilterRecord[]) {
    if (!this.setup) return;
    this.groups = this.filter.getGroups(this.setup, records, this.filtersSearch);
  }

  private initialize(setup: FilterSetup, query: Query | undefined) {
    this.records = query ? this.filter.getRecords(setup, query.filters) : [];
    this.search = query?.search || '';
    this.refresh(this.records);
  }
}
