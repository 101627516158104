import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';
import { AsyncPipe, JsonPipe } from '@angular/common';
import { TableTabComponent } from '@enginuity/core/organisms/table-tab/table-tab.component';
import { FilterTab, Query } from '@services/core-services/models';
import { FilterContext, FilterEntry, FilterService } from '@services/core-services/filters';

@Component({
  selector: 'app-filter-navigation',
  standalone: true,
  imports: [AsyncPipe, TableTabComponent, JsonPipe],
  templateUrl: './filter-navigation.component.html',
  styleUrl: './filter-navigation.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FilterNavigationComponent implements OnChanges {
  protected tabs: FilterTab[] = [];

  @Output() onSelectionChange: EventEmitter<FilterContext> = new EventEmitter<FilterContext>();
  @Output() onRemove: EventEmitter<{ context: FilterContext; active: boolean }> = new EventEmitter<{
    context: FilterContext;
    active: boolean;
  }>();

  @Input() filters: FilterContext[] | undefined | null = [];
  @Input() query: Query | undefined | null;

  constructor(private readonly filterService: FilterService) {}

  ngOnChanges(changes: SimpleChanges) {
    const filters = changes['filters']?.currentValue || this.filters || [];
    this.tabs = filters.map((f: FilterContext, i: number) => ({
      label: f.name,
      key: f.id,
      active: this.isActive(this.query, f),
      removable: !!f.id,
    }));
  }

  onTabChange(tab: FilterTab) {
    const filter = tab?.key
      ? this.filters?.find(f => f.id === tab.key)
      : this.filters?.find(f => f.name === tab.label);
    this.onSelectionChange.emit(filter);
  }

  handleRemove(tab: FilterTab) {
    const context = this.filters?.find(f => f.id === tab.key);
    this.onRemove.emit({ context: context!, active: tab.active || false });
  }

  private isActive(query: Query | undefined | null, context: FilterContext): boolean {
    const queryEntries = (query?.filters?.filters || []) as FilterEntry[];
    const recordEntries = context.records?.map(x => x.rule) || [];
    return this.filterService.compare(queryEntries, recordEntries);
  }
}
