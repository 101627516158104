<div class="d-flex spacing-4 w-100 align-items-center justify-content-between">
  <div class="d-flex spacing-4 align-items-center">
    <ng-container *ngFor="let record of records">
      <app-filter-entry-preview
        *ngIf="!record.entry.hidden"
        [record]="record"
        (onRemove)="onRecordRemove($event)"
        (click)="selectRecord(record, trigger)"
        cdkOverlayOrigin
        #trigger="cdkOverlayOrigin">
      </app-filter-entry-preview>
    </ng-container>
  </div>
  <div class="d-flex spacing-16">
    @if (records && records.length > 0) {
      <app-link-btn
        class="text-nowrap"
        label="Clear all"
        [active]="false"
        size="medium"
        IconType="none"
        [disabled]="false"
        (custom_click)="clearFilter()"></app-link-btn>
    }

    <app-link-btn
      [style.display]="records.length ? 'block' : 'none'"
      (custom_click)="openCreator()"
      cdkOverlayOrigin
      #trigger2="cdkOverlayOrigin"
      class="text-nowrap"
      label="Save as ..."
      [active]="false"
      size="medium"
      IconType="none"
      [disabled]="false"></app-link-btn>
  </div>
</div>

<ng-template
  cdkConnectedOverlay
  [cdkConnectedOverlayOrigin]="triggerOrigin!"
  [cdkConnectedOverlayOffsetY]="12"
  [cdkConnectedOverlayOpen]="editorOpen"
  (overlayOutsideClick)="closeEditor()">
  <div class="filter-editor">
    <app-filter-entry-editor
      (onApply)="onApply($event)"
      [record]="selectedRecord"></app-filter-entry-editor>
  </div>
</ng-template>

<ng-template
  class="filter-overlay"
  cdkConnectedOverlay
  [cdkConnectedOverlayOrigin]="trigger2!"
  [cdkConnectedOverlayOffsetY]="12"
  [cdkConnectedOverlayOpen]="creatorOpen"
  (overlayOutsideClick)="closeCreator()">
  <div class="filter-editor">
    <div class="d-flex flex-column spacing-16">
      <app-inputs [formControl]="filterNameCtrl" Label="Filter name"></app-inputs>
      <div class="d-flex spacing-16">
        <app-buttons label="Save" [disabled]="!filterNameCtrl.valid" (btnClick)="saveFilter()">
        </app-buttons>
        <app-secondary-btns label="Cancel" (onClick)="closeCreator()"></app-secondary-btns>
      </div>
    </div>
  </div>
</ng-template>
