<ng-container *ngIf="record" (keyup.enter)="onFilterApply()">
  <div class="d-flex align-items-center spacing-4">
    <span>{{ record.entry.label }}</span>

    <app-filter-operator-select
      [record]="record"
      (onChange)="onOperatorChange($event)"></app-filter-operator-select>

    <app-filter-entry-editor-container
      (onChange)="onFilterValueChange($event)"
      [record]="record"></app-filter-entry-editor-container>

    <app-buttons
      [shortcutKey]="false"
      IconType=""
      label="Apply"
      (btnClick)="onFilterApply()"></app-buttons>
  </div>
</ng-container>
