import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FilterRecord } from '@services/core-services/filters/fitler-record';
import { FilterEntryPreviewComponent } from '@enginuity/core/organisms/filtering/filter-entry-preview/filter-entry-preview.component';
import { CdkConnectedOverlay, CdkOverlayOrigin } from '@angular/cdk/overlay';
import { FilterEntryEditorContainerComponent } from '@enginuity/core/organisms/filtering/filter-entry-editor-container/filter-entry-editor-container.component';
import { SecondaryBtnsComponent } from '@enginuity/core/molecules/secondary-btns/secondary-btns.component';
import { FilterOperatorSelectComponent } from '@enginuity/core/organisms/filtering/filter-operator-select/filter-operator-select.component';
import { IconContainerComponent } from '@enginuity/core/atoms/icon-container/icon-container.component';
import { InputsComponent } from '@enginuity/core/molecules/inputs/inputs.component';
import { ButtonsComponent } from '@enginuity/core/molecules/buttons/buttons.component';
import { FilterEntryEditorComponent } from '@enginuity/core/organisms/filtering/filter-entry-editor/filter-entry-editor.component';
import { LinkBtnComponent } from '@enginuity/core/molecules/tertiary/link-btn.component';
import { FormControl, ReactiveFormsModule, Validators } from '@angular/forms';
import { FilterContext } from '@services/core-services/filters';

@Component({
  selector: 'app-filter-preview',
  standalone: true,
  templateUrl: './filter-preview.component.html',
  styleUrl: './filter-preview.component.scss',
  imports: [
    CommonModule,
    FilterEntryPreviewComponent,
    CdkOverlayOrigin,
    CdkConnectedOverlay,
    FilterEntryEditorContainerComponent,
    SecondaryBtnsComponent,
    FilterOperatorSelectComponent,
    IconContainerComponent,
    InputsComponent,
    ButtonsComponent,
    FilterEntryEditorComponent,
    LinkBtnComponent,
    ReactiveFormsModule,
  ],
})
export class FilterPreviewComponent implements OnChanges {
  @Input() records: FilterRecord[] = [];
  @Input() editorOpen: boolean = false;
  @Input() creatorOpen: boolean = false;

  @Output() onRemove: EventEmitter<FilterRecord> = new EventEmitter<FilterRecord>();
  @Output() clearAllFilter: EventEmitter<FilterRecord> = new EventEmitter<FilterRecord>();
  @Output() onSaveFilter: EventEmitter<FilterContext> = new EventEmitter<FilterContext>();
  @Output() onChange: EventEmitter<FilterRecord[]> = new EventEmitter<FilterRecord[]>();

  public selectedRecord: FilterRecord | undefined;
  public triggerOrigin: CdkOverlayOrigin | undefined;
  public filterNameCtrl = new FormControl('', [Validators.required]);

  ngOnChanges(changes: SimpleChanges) {
    const records = (changes['records']?.currentValue || []) as FilterRecord[];
    this.selectedRecord = records.find(record => record.active);
  }

  onRecordRemove(record: FilterRecord) {
    this.onRemove.emit(record);
  }

  clearFilter() {
    this.clearAllFilter.emit();
  }

  saveFilter() {
    const value = this.filterNameCtrl.value;
    this.onSaveFilter.emit({
      records: this.records,
      name: value!,
    });

    this.creatorOpen = false;
    this.filterNameCtrl.setValue('');
  }

  selectRecord(record: FilterRecord, trigger: CdkOverlayOrigin) {
    this.selectedRecord = record;
    this.triggerOrigin = trigger;
    this.editorOpen = true;
  }

  closeEditor() {
    this.selectedRecord = undefined;
    this.triggerOrigin = undefined;
    this.editorOpen = false;
  }

  openCreator() {
    this.creatorOpen = true;
  }

  closeCreator() {
    this.creatorOpen = false;
    this.filterNameCtrl.setValue('');
  }

  onApply(record: FilterRecord) {
    this.updateRecords(record);
    this.onChange.emit(this.records);
    this.closeEditor();
  }

  private updateRecords(record: FilterRecord) {
    this.records = this.records.map(r =>
      r.entry.field === record?.entry.field ? { ...record } : r
    );
  }
}
